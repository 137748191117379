import axios from "axios";

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Authorization"] = sessionStorage.getItem("token");

export const reloadHeader = () => {
  axios.defaults.headers.post["Authorization"] = sessionStorage.getItem("token");
};

export const getNotifications = (message, next) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `{
                    searchFirebaseNotifications(first: 10, after: "${next}", message_Icontains: "", firebase: false){
                        pageInfo{
                            endCursor
                            hasNextPage
                        }
                        edges{
                            node{
                                id
                                message
                                readClient
                                title
                                reservation {
                                   id
                                    total
                                    email
                                    tipoCobertura
                                    dias
                                    serviciosExtras
                                    tarifaDiaria
                                    comentarios
                                    fechaRenta
                                    fechaFin
                                    nombre
                                    apellidos
                                    telefono
                                    edad
                                    pagoId
                                    origen
                                    revisado
                                    noReserva
                                    fecha
                                    estado
                                    metodoPago
                                    cantidad
                                    categoria {
                                        id
                                        name
                                        tradicionalAlta
                                        totalAlta
                                        tradicionalBaja
                                        totalBaja
                                        isActive
                                        fecha
                                        image
                                        iconoWhite
                                        iconoBlack
                                        maletas
                                        maletasSm
                                        personas
                                        transmision
                                        lugar
                                    }
                                    sucursal {
                                        id
                                        name
                                        phone
                                        contactPhone
                                        address
                                        postalCod
                                        openHour
                                        closeHour
                                        isActive
                                    }
                                    agente {
                                        id
                                        username
                                        firstName
                                        lastName
                                        email
                                        isActive
                                        phone
                                    }
                                }
                            }
                        }
                    }
                }`
    }
  );
};

export const readMessage = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `mutation($id:Int!){
                    readNotification(id: $id){
                        info{
                            id
                            message
                            title
                            readClient
                            reservation {
                                   id
                                    total
                                    email
                                    tipoCobertura
                                    dias
                                    serviciosExtras
                                    tarifaDiaria
                                    comentarios
                                    fechaRenta
                                    fechaFin
                                    nombre
                                    apellidos
                                    telefono
                                    edad
                                    pagoId
                                    origen
                                    revisado
                                    noReserva
                                    fecha
                                    estado
                                    metodoPago
                                    cantidad
                                    categoria {
                                        id
                                        name
                                        tradicionalAlta
                                        totalAlta
                                        tradicionalBaja
                                        totalBaja
                                        isActive
                                        fecha
                                        image
                                        iconoWhite
                                        iconoBlack
                                        maletas
                                        maletasSm
                                        personas
                                        transmision
                                        lugar
                                    }
                                    sucursal {
                                        id
                                        name
                                        phone
                                        contactPhone
                                        address
                                        postalCod
                                        openHour
                                        closeHour
                                        isActive
                                    }
                                    agente {
                                        id
                                        username
                                        firstName
                                        lastName
                                        email
                                        isActive
                                        phone
                                    }
                                }
                        }
                   }
                 }`, variables: obj
    }
  );
};

export const getReservationsNoShow = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchReservations(
    $name: String!
    $before: String!
    $after: String!
    $first: Int!
) {
    searchReservationsNoshow(
        before: $before
        after: $after
        first: $first
        name:$name
    ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            cursor
            node {
                id
                noShow
                total
                email
                tipoCobertura
                dias
                serviciosExtras
                tarifaDiaria
                comentarios
                fechaRenta
                fechaFin
                nombre
                apellidos
                telefono
                edad
                pagoId
                origen
                revisado
                noReserva
                fecha
                estado
                metodoPago
                cantidad
                categoria {
                    id
                    name
                    tradicionalAlta
                    totalAlta
                    tradicionalBaja
                    totalBaja
                    isActive
                    fecha
                    image
                    iconoWhite
                    iconoBlack
                    maletas
                    maletasSm
                    personas
                    transmision
                    lugar
                }
                sucursal {
                    id
                    name
                    phone
                    contactPhone
                    address
                    postalCod
                    openHour
                    closeHour
                    isActive
                }
                agente {
                    id
                    username
                    firstName
                    lastName
                    email
                    isActive
                    phone
                }
            }
        }
    }
}`,
      variables: obj
    }
  );
};
export const getReservationsNoShowNext = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchReservations(
    $name: String!
    $after: String!
    $first: Int!
) {
    searchReservationsNoshow(
        after: $after
        first: $first
        before:""
        name:$name
    ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            cursor
            node {
                id
                noShow
                total
                email
                tipoCobertura
                dias
                serviciosExtras
                tarifaDiaria
                comentarios
                fechaRenta
                fechaFin
                nombre
                apellidos
                telefono
                edad
                pagoId
                origen
                revisado
                noReserva
                fecha
                estado
                metodoPago
                cantidad
                categoria {
                    id
                    name
                    tradicionalAlta
                    totalAlta
                    tradicionalBaja
                    totalBaja
                    isActive
                    fecha
                    image
                    iconoWhite
                    iconoBlack
                    maletas
                    maletasSm
                    personas
                    transmision
                    lugar
                }
                sucursal {
                    id
                    name
                    phone
                    contactPhone
                    address
                    postalCod
                    openHour
                    closeHour
                    isActive
                }
                agente {
                    id
                    username
                    firstName
                    lastName
                    email
                    isActive
                    phone
                }
            }
        }
    }
}`,
      variables: obj
    }
  );
};
export const getReservationsNoShowPrev = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchReservations(
    $name: String!
    $before: String!
    $after: String!
    $first: Int!
) {
    searchReservationsNoshow(
        before: $before
        after: $after
        first: $first
        name:$name
    ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            cursor
            node {
                id
                noShow
                total
                email
                tipoCobertura
                dias
                serviciosExtras
                tarifaDiaria
                comentarios
                fechaRenta
                fechaFin
                nombre
                apellidos
                telefono
                edad
                pagoId
                origen
                revisado
                noReserva
                fecha
                estado
                metodoPago
                cantidad
                categoria {
                    id
                    name
                    tradicionalAlta
                    totalAlta
                    tradicionalBaja
                    totalBaja
                    isActive
                    fecha
                    image
                    iconoWhite
                    iconoBlack
                    maletas
                    maletasSm
                    personas
                    transmision
                    lugar
                }
                sucursal {
                    id
                    name
                    phone
                    contactPhone
                    address
                    postalCod
                    openHour
                    closeHour
                    isActive
                }
                agente {
                    id
                    username
                    firstName
                    lastName
                    email
                    isActive
                    phone
                }
            }
        }
    }
}`,
      variables: obj
    }
  );
};
export const getReservations = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchReservations(
    $name: String!
    $before: String!
    $after: String!
    $first: Int!
    $origen:String!
) {
    searchReservations(
        before: $before
        after: $after
        first: $first
        origen_Icontains: $origen
        name:$name
        noShow: false
    ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            cursor
            node {
                id
                noShow
                total
                email
                tipoCobertura
                dias
                serviciosExtras
                tarifaDiaria
                comentarios
                fechaRenta
                fechaFin
                nombre
                apellidos
                telefono
                edad
                pagoId
                origen
                revisado
                noReserva
                fecha
                estado
                metodoPago
                cantidad
                extraservicereserveSet {
                    id
                    description
                    price
                    daily
                }
                categoria {
                    id
                    name
                    tradicionalAlta
                    totalAlta
                    tradicionalBaja
                    totalBaja
                    isActive
                    fecha
                    image
                    iconoWhite
                    iconoBlack
                    maletas
                    maletasSm
                    personas
                    transmision
                    lugar
                }
                sucursal {
                    id
                    name
                    phone
                    contactPhone
                    address
                    postalCod
                    openHour
                    closeHour
                    isActive
                }
                agente {
                    id
                    username
                    firstName
                    lastName
                    email
                    isActive
                    phone
                }
            }
        }
    }
}`,
      variables: obj
    }
  );
};
export const getReservationsNext = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchReservations(
    $name: String!
    $after: String!
    $first: Int!
    $origen:String!
) {
    searchReservations(
        after: $after
        first: $first
        origen_Icontains: $origen
        name:$name
        noShow: false
    ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            cursor
            node {
                id
                total
                email
                noShow
                tipoCobertura
                dias
                serviciosExtras
                tarifaDiaria
                comentarios
                fechaRenta
                fechaFin
                nombre
                apellidos
                telefono
                edad
                pagoId
                origen
                revisado
                noReserva
                fecha
                estado
                metodoPago
                cantidad
                categoria {
                    id
                    name
                    tradicionalAlta
                    totalAlta
                    tradicionalBaja
                    totalBaja
                    isActive
                    fecha
                    image
                    iconoWhite
                    iconoBlack
                    maletas
                    maletasSm
                    personas
                    transmision
                    lugar
                }
                sucursal {
                    id
                    name
                    phone
                    contactPhone
                    address
                    postalCod
                    openHour
                    closeHour
                    isActive
                }
                agente {
                    id
                    username
                    firstName
                    lastName
                    email
                    isActive
                    phone
                }
            }
        }
    }
}`,
      variables: obj
    }
  );
};
export const getReservationsPrev = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchReservations(
    $name: String!
    $before: String!
    $first: Int!
    $origen:String!
) {
    searchReservations(
        before: $before
        first: $first
        origen_Icontains: $origen
        name:$name
        noShow: false
    ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            cursor
            node {
                id
                total
                noShow
                email
                tipoCobertura
                dias
                serviciosExtras
                tarifaDiaria
                comentarios
                fechaRenta
                fechaFin
                nombre
                apellidos
                telefono
                edad
                pagoId
                origen
                revisado
                noReserva
                fecha
                estado
                metodoPago
                cantidad
                categoria {
                    id
                    name
                    tradicionalAlta
                    totalAlta
                    tradicionalBaja
                    totalBaja
                    isActive
                    fecha
                    image
                    iconoWhite
                    iconoBlack
                    maletas
                    maletasSm
                    personas
                    transmision
                    lugar
                }
                sucursal {
                    id
                    name
                    phone
                    contactPhone
                    address
                    postalCod
                    openHour
                    closeHour
                    isActive
                }
                agente {
                    id
                    username
                    firstName
                    lastName
                    email
                    isActive
                    phone
                }
            }
        }
    }
}`,
      variables: obj
    }
  );
};
export const CoberturasPerTarifaTotal = () => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query CoberturasPerTarifaTotal {
            coberturasPerTarifaTotal(name: "") {
                id
                cobertura
                isActive
            }
        }`,
    }
  );
};
export const CoberturasPerTarifaTradicional = () => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query CoberturasPerTarifaTradicional {
    coberturasPerTarifaTradicional(name: "") {
        id
        cobertura
        isActive
    }
}`,
    }
  );
};

export const getReservationsById = (id) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query ReservaById($id:ID!) {
    reservaById(id: $id) {
                id
                total
                noShow
                email
                tipoCobertura
                dias
                serviciosExtras
                tarifaDiaria
                comentarios
                fechaRenta
                fechaFin
                nombre
                apellidos
                telefono
                edad
                pagoId
                origen
                revisado
                noReserva
                fecha
                estado
                metodoPago
                cantidad
                categoria {
                    id
                    name
                    tradicionalAlta
                    totalAlta
                    tradicionalBaja
                    totalBaja
                    isActive
                    fecha
                    image
                    iconoWhite
                    iconoBlack
                    maletas
                    maletasSm
                    personas
                    transmision
                    lugar
                }
                sucursal {
                    id
                    name
                    phone
                    contactPhone
                    address
                    postalCod
                    openHour
                    closeHour
                    isActive
                }
                agente {
                    id
                    username
                    firstName
                    lastName
                    email
                    isActive
                    phone
                }
            }
        }`,
      variables: { id: id }
    }
  );
};
export const getNotificationsNotRead = () => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `{
                    searchFirebaseNotifications(readClient: false, firebase: false){
                        edges{
                            node{
                                id
                                message
                                readClient
                                title
                                reservation {
                                   id
                                    total
                                    email
                                    tipoCobertura
                                    dias
                                    serviciosExtras
                                    tarifaDiaria
                                    comentarios
                                    fechaRenta
                                    fechaFin
                                    nombre
                                    apellidos
                                    telefono
                                    edad
                                    pagoId
                                    origen
                                    revisado
                                    noReserva
                                    fecha
                                    estado
                                    metodoPago
                                    cantidad
                                    categoria {
                                        id
                                        name
                                        tradicionalAlta
                                        totalAlta
                                        tradicionalBaja
                                        totalBaja
                                        isActive
                                        fecha
                                        image
                                        iconoWhite
                                        iconoBlack
                                        maletas
                                        maletasSm
                                        personas
                                        transmision
                                        lugar
                                    }
                                    sucursal {
                                        id
                                        name
                                        phone
                                        contactPhone
                                        address
                                        postalCod
                                        openHour
                                        closeHour
                                        isActive
                                    }
                                    agente {
                                        id
                                        username
                                        firstName
                                        lastName
                                        email
                                        isActive
                                        phone
                                    }
                                }
                            }
                        }
                    }
                }`
    }
  );
};
export const createExtraServiceToReserve = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `mutation CreateServiceToReserve(
                $daily:Boolean!
                $description:String!
                $price:Decimal!
                $reserve:ID!
            ) {
                createServiceToReserve(
                    daily: $daily
                    description: $description
                    price: $price
                    reserve: $reserve
                ) {
                    success
                    error
                }
            }
            `, variables: obj
    }
  );
};
export const createExtraServiceToContract = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `mutation CreateServiceToContract(
                $contract:ID!
                $daily:Boolean!
                $description:String!
                $price:Decimal!
            ) {
                createServiceToContract(
                    contract: $contract
                    daily: $daily
                    description: $description
                    price: $price
                ) {
                    success
                    error
                }
            }`, variables: obj
    }
  );
};
export const serviceByContract = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query ServicioPorContrato(
                $idServicio:Int!
                $idContrato:String!
            ) {
                servicioPorContrato(idServicio: $idServicio, idContrato: $idContrato)
            }`, variables: obj
    }
  );
};
export const servicesPerName = () => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query ServiciosPerName {
                serviciosPerName(name: "") {
                    id
                    isActive
                    name
                    diario
                    precio
                    icono
                }
            }`
    }
  );
};