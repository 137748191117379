<template xmlns="http://www.w3.org/1999/html">
  <div class="ps-4 pe-4 pb-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header bg-gradient-warning">
            <h5 class="mb-0 text-white">
              Vehículos a entregar en el dia
            </h5>
            <p class="mb-0 text-sm">
            </p>
          </div>
          <div class="d-flex justify-content-between m-3">
            <div class="col-3">
              <div class="input-group">
                <input v-model="misearch" type="text" class="form-control input-icon"
                       placeholder="Buscar vehículo a entregar" aria-label="Recipient's username"
                       aria-describedby="button-addon2" autocomplete="new-password">
              </div>
            </div>
            <div class="col-9 d-flex justify-content-end">
              <VueDatePicker cancelText="Cancelar" selectText="Aceptar" locale="es"
                             class="w-50 form_fecha" utc="preserve"
                             v-model="fecha" placeholder="Seleccione una fecha"
                             format="dd/MM/yyyy hh:mm aa"
              ></VueDatePicker>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table" id="miTabla">
              <thead class="thead-light">
              <tr class="text-center align-middle">
                <th>Estado</th>
                <th>Número</th>
                <th>Cliente</th>
                <th>Categoría</th>
                <th>Sucursal</th>
                <th>Salida - Regreso</th>
                <th>Monto</th>
                <th>Acciones</th>
              </tr>
              </thead>
              <tbody>
              <tr class="text-center align-middle" v-for="item in reservasEntrega" :key="item.id"
                  :class="esHoy(item.fechaRenta)?'es-hoy':''">
                <td class="text-sm font-weight-normal">
                  {{ item.estado }}
                </td>
                <td class="text-sm font-weight-normal">
                  {{ item.noReserva }}
                </td>
                <td class="text-sm font-weight-normal">
                  {{ item.nombre }} {{ item.apellidos }}
                </td>
                <td class="text-sm font-weight-normal">
                  {{ item.categoria.name }}
                </td>
                <td class="text-sm font-weight-normal">
                  {{ item.sucursal.name }}
                </td>
                <td class="text-sm font-weight-normal">
                  {{ formatearFechaCorta(item.fechaRenta) }} -<br> {{ formatearFechaCorta(item.fechaFin)
                  }}
                </td>
                <td class="text-sm font-weight-normal">
                  {{ item.estado === "No pagado" ? "Solo reserva" : "$ " + numeroFormateado(item.total) + " MXN" }}
                </td>
                <td>
                  <div class="btn-group pt-3" role="group">
                    <button @click="openDetallesReserva(item)" data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Detalles de la reserva" data-container="body" data-animation="true"
                            class="btn btn-secondary p-1 ms-1">
                      <i class="material-icons opacity-10">visibility</i></button>
                    <button @click="Contrato(item)"
                            data-bs-toggle="tooltip" v-if="esHoy(item.fechaRenta)"
                            data-bs-placement="top" title="Crear contrato" data-container="body"
                            data-animation="true" class="btn btn-primary p-1 ms-1">
                      <i class="material-icons opacity-10">description</i></button>
                    <!--                    <button @click="UpdateReserva(item)"-->
                    <!--                            data-bs-toggle="tooltip" data-bs-placement="top" title="Editar reserva"-->
                    <!--                            data-container="body" data-animation="true" class="btn btn-info p-1 ms-1">-->
                    <!--                      <i class="material-icons opacity-10">edit</i></button>-->
                    <button v-if="tienePermiso('cancelar_reservas')" @click="Cancelar_Reserva(item.id)"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top" title="Cancelar la reserva" data-container="body"
                            data-animation="true"
                            class="btn btn-danger p-1 ms-1">
                      <i class="material-icons opacity-10">cancel</i></button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal  DetallesVehiculo">
    <div v-if="showCar" :class="['modal', { 'show': showCar }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="h-100">
          <div class="row mb-3 border-bottom border-dark">
            <h4 class="text-start"><i class="fa fa-bars me-2"></i>Detalles del Vehículo<i @click="closeCar"
                                                                                          class="material-icons-round opacity-10 modal-icon">close</i>
            </h4>
          </div>
          <div class="p-3 card-body">
            <div class="row">
              <div class="col-lg-4 col-sm-12">
                <ul class="list-group">
                  <li class="pt-0 text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">No. Económico:</strong> &nbsp;
                    {{ this.car.noEcon }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Categoría:</strong> &nbsp; {{ this.car.categoria.name }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Año:</strong> &nbsp; {{ this.car.anno }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Marca:</strong> &nbsp;
                    {{ this.car.version.modelo.marca.name }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Modelo:</strong> &nbsp;
                    {{ this.car.version.modelo.name }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Versión:</strong> &nbsp;
                    {{ this.car.version.name }}
                  </li>
                </ul>
              </div>
              <div class="col-lg-4 col-sm-12">
                <ul class="list-group">
                  <li class="pt-0 text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Titular:</strong> &nbsp;
                    {{ this.car.titular.name }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Aseguradora:</strong> &nbsp; {{ this.car.aseguradora.name }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Placa:</strong> &nbsp; {{ this.car.placa }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Estatus:</strong> &nbsp;
                    {{ this.car.estatus }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Cap. del Tanque:</strong> &nbsp;
                    {{ this.car.capacidadTanke }} lts
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Estado Emplacado:</strong> &nbsp;
                    {{ this.car.estado.name }}
                  </li>
                </ul>
              </div>
              <div class="col-lg-4 col-sm-12">
                <ul class="list-group">
                  <li class="pt-0 text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Color:</strong> &nbsp;
                    {{ this.car.color }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Número de Serie:</strong> &nbsp;
                    {{ this.car.numeroSerie }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Fecha de Alta:</strong> {{ this.car.fechaAlta }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Transmisión:</strong> {{ this.car.transmision }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Sucursal:</strong> &nbsp;
                    {{ this.car.sucursal.name }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="row">
              <div class="col-6 d-flex justify-content-start">
              </div>
              <div class="col-6 d-flex justify-content-end">
                <button @click="closeCar" class="btn btn-dark" type="button">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal DetallesContrato">
    <div v-if="showDetalles" :class="['modal', { 'show': showDetalles }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="h-100">
          <div class="row mb-3 border-bottom border-dark">
            <h4 class="text-start"><i class="fa fa-bars me-2"></i>Detalles del contrato<i @click="closeDetalles"
                                                                                          class="material-icons-round opacity-10 modal-icon">close</i>
            </h4>
          </div>
          <div class="p-3 card-body">
            <div class="row">
              <h6 class="text-bold">Información del cliente</h6>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Nombre y apellidos:</strong>
                <p>{{ item.cliente.nombre }} {{ item.cliente.apellidos }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Correo:</strong>
                <p>{{ item.cliente.email }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Teléfono:</strong>
                <p>{{ item.cliente.telefono }}</p>
              </div>
              <h6 class="text-bold">Condiciones de renta</h6>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>No. Folio:</strong>
                <p>{{ item.numContrato }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Fecha de Contrato:</strong>
                <p>{{ formatearFecha(item.fecha) }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Estado del contrato:</strong>
                <p>{{ item.estado }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Sucursal:</strong>
                <p>{{ item.sucursal.name }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Canal:</strong>
                <p>{{ item.canal === "portal" ? "Portal" : "Administración" }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Agente:</strong>
                <p>{{ item.agente.firstName }} {{ item.agente.lastName }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Fecha de salida:</strong>
                <p>{{ formatearFecha(item.fechaSalida) }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Fecha de regreso:</strong>
                <p>{{ formatearFecha(item.fechaRegreso) }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Días reservados:</strong>
                <p>{{ item.dias }}</p>
              </div>
              <h6 class="text-bold">Información del auto</h6>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Categoría:</strong>
                <p>{{ item.vehiculo.categoria.name }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Marca:</strong>
                <p>{{ item.vehiculo.version.modelo.marca.name }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Modelo:</strong>
                <p>{{ item.vehiculo.version.modelo.name }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Color:</strong>
                <p>{{ item.vehiculo.color }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Placa:</strong>
                <p>{{ item.vehiculo.placa }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Cantidad del tanque(Litros):</strong>
                <p>{{ item.vehiculo.capacidadTanke }} lts</p>
              </div>
              <h6 class="text-bold">Tarifas</h6>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Tarifa diaria:</strong>
                <p>$ {{ numeroFormateado(item.tarifaDiaria) }} MXN</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Tarifa de horas extras</strong>
                <p>$ {{ numeroFormateado(item.precioHoraExtra) }} MXN</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Servicios extras:</strong>
                <p>$ {{ numeroFormateado(item.tarifaExtra) }} MXN</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Total:</strong>
                <p>$ {{ numeroFormateado(item.total) }} MXN</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Método de pago:</strong>
                <p>{{ item.tipoPago === "tarjeta" ? "Tarjeta" : "Efectivo" }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4" v-show="item.tipoPago==='tarjeta'">
                <strong>Check in:</strong>
                <p>$ {{ numeroFormateado(item.checkin) }} MXN</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4" v-show="item.tipoPago==='tarjeta'">
                <strong>No. tarjeta:</strong>
                <p>{{ item.tarjeta ? item.tarjeta.numero : "" }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4" v-show="item.tipoPago==='tarjeta'">
                <strong>No. autorización:</strong>
                <p>{{ item.tarjeta ? item.tarjeta.autorizacion : "" }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4" v-show="item.tipoPago==='tarjeta'">
                <strong>Fecha de vencimiento:</strong>
                <p>{{ item.tarjeta ? formatearFecha(item.tarjeta.vencimiento) : "" }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4" v-show="item.tipoPago==='efectivo'">
                <strong>Depósito en efectivo:</strong>
                <p>$ {{ numeroFormateado(item.depositoEfectivo) }} MXN</p>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <button @click="closeDetalles" class="mt-3 btn btn-dark text-end">Cerrar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal DetallesReserva">
    <div v-if="showDetallesReserva" :class="['modal', { 'show': showDetallesReserva }]"
         @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-bars me-2"></i>Detalles de la reserva<i @click="closeDetallesReserva"
                                                                                         class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <div class="col-lg-6 col-sm-12">
            <ul class="list-group">
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">No. Reserva:</strong> &nbsp;
                {{ this.item.noReserva }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Nombre:</strong> &nbsp;
                {{ this.item.nombre }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Email:</strong> &nbsp;
                {{ this.item.email }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Teléfono:</strong> {{ this.item.telefono }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Fecha de Renta:</strong><br>
                {{ formatearFecha(this.item.fechaRenta) }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Días:</strong> &nbsp;
                {{ this.item.dias }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Cobertura {{ item.tipoCobertura }}:</strong> &nbsp;
                $ {{ numeroFormateado(this.item.tarifaDiaria) }} MXN
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Total de renta: </strong>$ {{ numeroFormateado(this.total_renta) }}
                MXN
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Total (Impuestos incluidos): </strong>$ {{ numeroFormateado(this.monto_total)
                }} MXN
              </li>
            </ul>
          </div>
          <div class="col-lg-6 col-sm-12">
            <ul class="list-group">
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Procedencia:</strong> &nbsp;
                {{ this.item.origen === "portal" ? "Portal web" : this.item.origen }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Apellidos:</strong> &nbsp;
                {{ this.item.apellidos }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Edad:</strong> &nbsp; {{ this.item.edad }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Sucursal:</strong> &nbsp; {{ this.item.sucursal.name }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Fecha de Regreso:</strong><br>
                {{ formatearFecha(this.item.fechaFin) }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Categoría:</strong> &nbsp; {{ this.item.categoria.name }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Forma de
                  pago: </strong>{{ this.item.metodoPago === "efectivo" ? "Efectivo" : "Tarjeta" }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Total de servicios extras: </strong>$
                {{ numeroFormateado(this.item.serviciosExtras) }}
                MXN
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Total pagado: </strong>$ {{ numeroFormateado(this.item.total) }} MXN
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <ul class="list-group">
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Comentarios: </strong>{{ this.item.comentarios ? this.item.comentarios : "" }}
              </li>
            </ul>
          </div>
          <div class="col-6">
            <ul class="list-group">
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Servicios extras: </strong>
                <ul>
                  <li v-for="item in services_ex" :key="item.id">{{ item.servicio.name }}</li>
                  <li v-if="this.item.extraservicereserveSet.length>0">
                    {{ this.item.extraservicereserveSet[0].description }}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="closeDetallesReserva" class="btn btn-secondary" type="button">Cerrar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal ActualizarReserva">
    <div v-if="showUpdate" :class="['modal', { 'show': showUpdate }]" @transitionend="onTransitionEnd">
      <div class="modal-content mt-sm-3">
        <div class="row ps-2 pe-2 mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-plus-square me-2"></i>Editar reserva<i @click="closeUpdate"
                                                                                        class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row ps-2 pe-2" v-show="pagina_uno">

          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Fecha de renta<span class="text-danger ms-1">*</span></label>
            <VueDatePicker cancelText="Cancelar" selectText="Aceptar" locale="es"
                           placeholder="Seleccione una fecha" format="dd/MM/yyyy hh:mm aa"
                           minutes-increment="60" no-minutes-overlay :start-time="{minutes: 0}"
                           v-model="fechaRenta" class="form_fecha" :class="fechaRenta_error?'error_fecha':''">
            </VueDatePicker>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Fecha de regreso<span class="text-danger ms-1">*</span></label>
            <VueDatePicker cancelText="Cancelar" selectText="Aceptar" locale="es"
                           placeholder="Seleccione una fecha" format="dd/MM/yyyy hh:mm aa"
                           minutes-increment="60" no-minutes-overlay :start-time="{minutes: 0}"
                           v-model="fechaFin" class="form_fecha" :class="fechaFin_error?'error_fecha':''">
            </VueDatePicker>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Días<span class="text-danger ms-1">*</span></label>
            <input v-model="dias" disabled type="number" class="form-control p-2 border border-secondary">
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Categoría<span class="text-danger ms-1">*</span></label>
            <select :disabled="deshabilitar_categoria" v-model="categoria"
                    class="form-select" :class="categoria_error?'is-invalido':''">
              <option :value="categoria_id">{{ categoria_name }}</option>
              <option v-for="item in allCategoriasAdmin" :value="item.id" :key="item.id">{{ item.name }}
              </option>
            </select></div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Tipo de cobertura<span class="text-danger ms-1">*</span></label>
            <select v-model="tipo" class="form-select">
              <option selected disabled :value="0">Seleccione una tarifa</option>
              <option value="total">Cobertura total : {{ tarifa_total }}</option>
              <option value="tradicional">Cobertura tradicional : {{ tarifa_tradicional }}</option>
            </select></div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Cantidad<span class="text-danger ms-1">*</span></label>
            <input type="number" class="form-control" :value="cantidad">
          </div>

          <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">Debe llenar todos los campos</p></div>
          </div>
          <div v-show="error_fechas" class="text-danger mt-3 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">Error en las fechas</p></div>
          </div>
          <div v-show="phone_error" class="text-danger mt-3 text-center p-2 mb-3"
               style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">El número de teléfono es inválido</p></div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-between ">
            <button @click="closeUpdate" class="btn btn-secondary" type="button">Cancelar</button>
            <button @click="Pagina_2" class="btn btn-dark" type="button">Siguiente</button>
          </div>
        </div>
        <div class="row ps-2 pe-2" v-show="pagina_dos">
          <div class="row mb-2 services_extras_container">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 services_extras mb-2 shadow rounded"
                 v-for="item in elementosPaginaActual" :key="item.id">
              <div class="d-flex align-items-center">
                <img :src="getURL(item.icono)" class="img-fluid me-3" style="height: 35px; width: 35px">
                <span>{{ item.name }}</span>
              </div>
              <div class="d-flex align-items-center">
                <a :id="'adicionar'+item.id" class="btn btn-issy mb-0"
                   style="width: 315px" :class="services[item.id]?'d-none':''"
                   @click="Adicionar(item.id, item.precio, item.diario, item.name)">
                  <i class="fa fa-plus-square"></i> Adicionar<span
                  class="ms-2">$ {{ item.precio }} MXN</span>
                  <span v-if="item.diario" class="ms-1">/ día</span>
                  <span v-else class="ms-1">/ único pago</span>
                </a>
                <a :id="'quitar'+item.id" class="btn btn-primary1 mb-0"
                   style="width: 315px" :class="services[item.id]?'':'d-none'"
                   @click="Quitar(item.id, item.precio, item.diario, item.name)">
                  <i class="fa fa-minus-square"></i> Quitar<span
                  class="ms-2">$ {{ item.precio }} MXN</span>
                  <span v-if="item.diario" class="ms-1">/ día</span>
                  <span v-else class="ms-1">/ único pago</span>
                </a>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <button @click="paginaAnterior" class="btn btn-dark me-3"><i class="material-icons opacity-10">arrow_back_ios</i>
            </button>
            <button v-for="index in totalPaginas" :key="index" @click="irPaginaActual(index)"
                    class="btn me-3" :class="index===paginaActual?'btn-info':'btn-secondary'">{{ index }}
            </button>
            <button @click="paginaSiguiente" class="btn btn-dark"><i
              class="material-icons opacity-10">arrow_forward_ios</i></button>
          </div>
          <div class="row">
            <div
              class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ps-2 pe-2 mb-2 shadow rounded">
              <h5 class="text-center">Otro servicio</h5>
              <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12 mb-2">
                  <label class="form-label">Descripción</label>
                  <input type="text" class="form-control p-2 border border-secondary">
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 mb-2">
                  <label class="form-label">Precio</label>
                  <input type="number" class="form-control p-2 border border-secondary" v-model="precio_otro">
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 mb-2">
                  <div class="form-check p-0">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="pago_diario"
                      v-model="diario_otro"
                    />
                    <label for="pago_diario" class="h6 text-bold mb-1">Pago diario</label>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 mb-2">
                  <div class="d-flex align-items-center">
                    <a :id="'adicionar'+'otro'" class="btn w-100 btn-issy mb-0"
                       @click="Adicionar('otro', precio_otro, diario_otro)">
                      <i class="fa fa-plus-square"></i> Adicionar<span
                      class="ms-2">$ {{ precio_otro }} MXN</span>
                      <span class="ms-1">/ {{ diario_otro ? "Día" : "Único pago" }}</span>
                    </a>
                    <a :id="'quitar'+'otro'" class="btn w-100 btn-primary1 mb-0 d-none"
                       @click="Quitar('otro', precio_otro, diario_otro)">
                      <i class="fa fa-minus-square"></i> Quitar<span
                      class="ms-2">$ {{ precio_otro }} MXN</span>
                      <span class="ms-1">/ {{ diario_otro ? "Día" : "Único pago" }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-between">
              <button @click="Pagina_1" class="btn btn-secondary" type="button">Regresar</button>
              <button @click="Pagina_3" class="btn btn-dark" type="button">Siguiente</button>
            </div>
          </div>
        </div>
        <div class="row ps-2 pe-2" v-show="pagina_tres">
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Nombre<span class="text-danger ms-1">*</span></label>
            <input class="form-control dropdown mb-3" type="text"
                   v-model="nombre" :class="nombre_error?'is-invalido':''"
                   @input="autocomplete">
            <ul v-if="showSubmenu" class="position-absolute z-index2 bg-light">
              <li class="dropdown-item cursor-pointer d-block" v-for="item in autocompleteItems"
                  :key="item.id"
                  @click="selectItem(item)">{{ item.nombre }} {{ item.apellidos }}
              </li>
            </ul>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Apellidos<span class="text-danger ms-1">*</span></label>
            <input :disabled="deshabilitado" v-model="apellidos" type="text" :class="apellidos_error?'is-invalido':''"
                   class="form-control"></div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Correo<span class="text-danger ms-1">*</span></label>
            <input :disabled="deshabilitado" v-model="email" type="email" :class="correo_error?'is-invalido':''"
                   class="form-control"></div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Edad<span class="text-danger ms-1">*</span></label>
            <input :disabled="deshabilitado" v-model="edad" type="number" :class="edad_error?'is-invalido':''"
                   class="form-control"></div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Teléfono<span class="text-danger ms-1">*</span></label>
            <input :disabled="deshabilitado" id="telefono" v-model="telefono" pattern="[0-9]*" type="text"
                   class="form-control" :class="telefono_error?'is-invalido':''" maxlength="10">
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Sucursal<span class="text-danger ms-1">*</span> </label>
            <select v-bind:disabled="this.$store.state.rol!=='Super Administrador'"
                    :class="sucursal_error?'text-gray is-invalido':''" v-model="sucursal"
                    class="form-select mb-3">
              <option selected disabled value="0">Seleccione una Sucursal</option>
              <option v-for="item in allSucursales" :value="item.id" v-show="item.isActive">{{ item.name }}
              </option>
            </select></div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Tarifa<span class="text-danger ms-1">*</span></label>
            <input v-model="tarifa" :min="cobertura" type="number"
                   class="form-control" :class="tarifa_vacia_error?'is-invalido':''">
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Total de servicios extras<span class="text-danger ms-1">*</span></label>
            <input disabled v-model="total_extras" type="number" class="form-control p-2 border border-secondary">
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Total (impuestos incluidos)<span class="text-danger ms-1">*</span></label>
            <input disabled v-model="total" type="number" class="form-control"></div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Forma de pago<span class="text-danger ms-1">*</span></label>
            <select class="form-select mb-3" v-model="tipo_pago" :class="tipo_pago_error?'is-invalido':''">
              <option value="tarjeta">Tarjeta</option>
              <option value="efectivo">Efectivo</option>
            </select>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Total pagado<span class="text-danger ms-1">*</span></label>
            <input v-model="total_pagado" type="number" class="form-control"></div>

          <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
            <label class="form-label">Comentarios</label>
            <textarea class="form-control" v-model="comentarios"></textarea>
          </div>
          <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">Debe llenar todos los campos</p></div>
          </div>
          <div v-show="phone_error" class="text-danger mt-3 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">El número de teléfono es inválido</p></div>
          </div>
          <div v-show="email_error" class="text-danger mt-3 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">El correo es inválido</p></div>
          </div>
          <div v-show="tarifa_error" class="text-danger mt-3 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">La tarifa debe ser mayor o igual que la cobertura</p></div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-between mt-3">
            <button @click="Pagina_2" class="btn btn-secondary" type="button">Regresar</button>
            <button @click="Actualizar" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import MaterialButton from "@/components/MaterialButton.vue";
import Swal from "sweetalert2";
import "datatables.net";
import moment from "moment";
import html2pdf from "html2pdf.js";
import Contrato from "@/components/Contrato.vue";
import vue3html2pdf from "vue3-html2pdf";
import { mapGetters } from "vuex";
import VueDatePicker from "@vuepic/vue-datepicker";

const CONTRATO_ENTREGA = gql`
query ContratosEntrega($fecha: Date!, $name: String!) {
    contratosEntrega(fecha: $fecha, name: $name) {
        id
        estado
        tipoCobertura
        seguroRc
        revisado
        tipoPago
        fecha
        precioHoraExtra
        numContrato
        fechaSalida
        fechaRegreso
        tarifaDiaria
        tarifaExtra
        dias
        tanque
        deuda
        total
        depositoEfectivo
        checkin
        credito
        comentarios
        canal
        agente {
            id
            username
            firstName
            lastName
            email
        }
        cliente {
            id
            nombre
            apellidos
            direccion
            telefonoEmergencia
            telefono
            email
            edad
            licencia {
                numero
                vencimiento
                estado {
                    name
                }
            }
        }
        vehiculo {
            id
            placa
            noEcon
            color
            capacidadTanke
            titular {
                id
                name
                isActive
            }
            aseguradora {
                id
                name
                isActive
            }
            estado {
                id
                name
                abreviatura
                isActive
            }
            sucursal {
                id
                name
            }
            version{
              name
              modelo{
                name
                marca{
                  name
                }
              }
            }
            categoria {
                id
                name
                tradicionalAlta
                totalAlta
                tradicionalBaja
                totalBaja
            }
        }
        sucursal {
            id
            name
        }
        conductor {
            id
            nombre
            apellidos
            edad
            licencia {
                id
                numero
                vencimiento
                permanente
                estado {
                    name
                }
            }
            cliente {
                id
                nombre
                apellidos
                telefono
                email
                edad
                isActive
                direccion
                postal
                telefonoEmergencia
            }
        }
        tarjeta {
            id
            numero
            vencimiento
            autorizacion
        }
    }
}`;
const CONTRATO_ENTREGA_SIN_FECHA = gql`
query ContratosEntregaSinFecha($name:String!) {
    contratosEntregaSinFecha(name: $name) {
        id
        estado
        tipoCobertura
        seguroRc
        revisado
        tipoPago
        fecha
        precioHoraExtra
        numContrato
        fechaSalida
        fechaRegreso
        tarifaDiaria
        tarifaExtra
        dias
        tanque
        total
        deuda
        depositoEfectivo
        checkin
        credito
        comentarios
        canal
        agente {
            id
            username
            firstName
            lastName
            email
        }
        cliente {
            id
            nombre
            apellidos
            direccion
            telefonoEmergencia
            telefono
            email
            edad
            licencia {
                numero
                vencimiento
                estado {
                    name
                }
            }
        }
        vehiculo {
            id
            placa
            noEcon
            color
            capacidadTanke
            titular {
                id
                name
                isActive
            }
            aseguradora {
                id
                name
                isActive
            }
            estado {
                id
                name
                abreviatura
                isActive
            }
            sucursal {
                id
                name
            }
            version{
              name
              modelo{
                name
                marca{
                  name
                }
              }
            }
            categoria {
                id
                name
                tradicionalAlta
                totalAlta
                tradicionalBaja
                totalBaja
            }
        }
        sucursal {
            id
            name
        }
        conductor {
            id
            nombre
            apellidos
            edad
            licencia {
                id
                numero
                vencimiento
                permanente
                estado {
                    name
                }
            }
            cliente {
                id
                nombre
                apellidos
                telefono
                email
                edad
                isActive
                direccion
                postal
                telefonoEmergencia
            }
        }
        tarjeta {
            id
            numero
            vencimiento
            autorizacion
        }
    }
}`;
const RESERVA_ENTREGA = gql`
query ReservasEntrega($fecha: DateTime!, $name: String!) {
    reservasEntrega(fecha: $fecha, name: $name) {
            id
            noShow
          total
          email
          tipoCobertura
          dias
          serviciosExtras
          tarifaDiaria
          comentarios
          fechaRenta
          fechaFin
          nombre
          apellidos
          telefono
          edad
          pagoId
          origen
          revisado
          noReserva
          fecha
          estado
          metodoPago
          cantidad
          extraservicereserveSet {
                    id
                    description
                    price
                    daily
                }
          categoria {
              id
              name
              tradicionalAlta
              totalAlta
              tradicionalBaja
              totalBaja
              isActive
              fecha
              image
              iconoWhite
              iconoBlack
              maletas
              maletasSm
              personas
              transmision
              lugar
          }
          sucursal {
              id
              name
              phone
              contactPhone
              address
              postalCod
              openHour
              closeHour
              isActive
          }
          agente {
              id
              username
              firstName
              lastName
              email
              isActive
              phone
          }
      }
    }`;
const RESERVA_ENTREGA_SIN_FECHA = gql`
query ReservasEntregaSinFecha($name:String!) {
    reservasEntregaSinFecha(name: $name) {
            id
            noShow
        total
        email
        tipoCobertura
        dias
        serviciosExtras
        tarifaDiaria
        comentarios
        fechaRenta
        fechaFin
        nombre
        apellidos
        telefono
        edad
        pagoId
        origen
        revisado
        noReserva
        fecha
        estado
        metodoPago
        cantidad
        extraservicereserveSet {
                    id
                    description
                    price
                    daily
                }
        categoria {
            id
            name
            tradicionalAlta
            totalAlta
            tradicionalBaja
            totalBaja
            isActive
            fecha
            image
            iconoWhite
            iconoBlack
            maletas
            maletasSm
            personas
            transmision
            lugar
        }
        sucursal {
            id
            name
            phone
            contactPhone
            address
            postalCod
            openHour
            closeHour
            isActive
        }
        agente {
            id
            username
            firstName
            lastName
            email
            isActive
            phone
        }
    }
 }`;
const ENTREGAR_AUTO = gql`
mutation EntregarAuto($id:ID!) {
    entregarAuto(id: $id) {
        success
        error
    }
}`;
const CANCELAR_CONTRATO = gql`
mutation CancelarContrato($id:Int!) {
    cancelarContrato(id: $id) {
        success
        error
    }
}`;
const CANCELAR = gql`mutation CancelarReserva($id:Int!, $agente:ID!) {
    cancelarReserva(id: $id, agente:$agente) {
        success
        error
    }
}`;
const SERVICIOS_BY_RESERVE = gql`
query ServicesByReservation($id:ID!) {
    servicesByReservation(id: $id) {
        id
        servicio {
            name
        }
    }
}`;
export default {
  name: "List_Categorias",
  components: { VueDatePicker, MaterialButton, Contrato, vue3html2pdf },
  data() {
    return {
      contratosEntrega: [],
      services_ex: [],
      reservasEntrega: [],
      misearch: "",
      vehiculosReservados: [],
      allSucursales: [],
      allCategoriasAdmin: [],
      showModal: false,
      showFechas: false,
      showCar: false,
      error: false,
      fechaCirculacion: null,
      fechaCredito: null,
      fechaPoliza: null,
      estatus: "",
      status: 0,
      carid: 0,
      preview: null,
      imagen: null,
      car: null,
      sucursal: null,
      ImageModal: false,
      ImageUpdateModal: false,
      salida: null,
      regreso: null,
      categoria_id: 0,
      monto_total: 0,
      total_renta: 0,
      showDetalles: false,
      showDetallesReserva: false,
      item: null,
      fecha: null
    };
  },
  watch: {
    fecha(val) {
      this.loadReservas();
    },
    misearch() {
      this.loadReservas();
    }
  },
  methods: {
    esHoy(item) {
      const fecha = new Date(item);
      const hoy = new Date();
      return fecha.getDate() === hoy.getDate() &&
        fecha.getMonth() === hoy.getMonth() &&
        fecha.getFullYear() === hoy.getFullYear();
    },
    loadReservas() {
      this.$store.state.Cargando = true;
      if (this.fecha === null) {
        this.$apollo.query({
          query: RESERVA_ENTREGA_SIN_FECHA,
          variables: {
            name: this.misearch
          },
          fetchPolicy: "network-only"
        }).then(resp => {
          this.reservasEntrega = resp.data.reservasEntregaSinFecha;
          this.$store.state.Cargando = false;
        });
      } else {
        this.$apollo.query({
          query: RESERVA_ENTREGA,
          variables: {
            name: this.misearch,
            fecha: this.fecha
          },
          fetchPolicy: "network-only"
        }).then(resp => {
          this.reservasEntrega = resp.data.reservasEntrega;
          this.$store.state.Cargando = false;
        });
      }
    },
    loadContratos() {
      this.$store.state.Cargando = true;
      if (this.fecha === null) {
        this.$apollo.query({
          query: CONTRATO_ENTREGA_SIN_FECHA,
          variables: {
            name: this.misearch
          },
          fetchPolicy: "network-only"
        }).then(resp => {
          this.contratosEntrega = resp.data.contratosEntregaSinFecha;
          this.$store.state.Cargando = false;
        });
      } else {
        this.$apollo.query({
          query: CONTRATO_ENTREGA,
          variables: {
            name: this.misearch,
            fecha: this.fecha
          }
        }).then(resp => {
          this.contratosEntrega = resp.data.contratosEntrega;
          this.$store.state.Cargando = false;
        });
      }
    },
    openReserva(item) {
      this.item = item;
      this.monto_total = parseFloat(item.serviciosExtras) + (parseFloat(item.tarifaDiaria) * parseInt(item.dias));
      this.total_renta = parseFloat(item.tarifaDiaria) * parseInt(item.dias);
      this.showDetalles = true;
      document.body.classList.add("modal-open");
    },
    openCar(item) {
      this.car = item;
      this.showCar = true;
      document.body.classList.add("modal-open");
    },
    openContrato(item) {
      this.item = item;
      this.showDetalles = true;
      document.body.classList.add("modal-open");
    },
    closeDetalles() {
      this.item = null;
      this.showDetalles = false;

    },
    closeCar() {
      this.car = null;
      this.showCar = false;
    },
    Difechas(fecha1, fecha2) {
      return moment(fecha2).diff(moment(fecha1), "hours");
    },
    onTransitionEnd(event) {
      if (event.propertyName === "opacity" && !this.showModal) {
        this.$emit("close");
      }
    },
    formatearFecha(fecha) {
      moment.locale("es");
      return moment(fecha).format("dddd, D [de] MMMM [de] YYYY h:mm A");
    },
    formatearFechaCorta(fecha) {
      moment.locale("es");
      return moment(fecha).format("dddd, D [de] MMMM [de] YYYY h:mm A");
    },
    numeroFormateado(numero) {
      const num = parseFloat(numero);
      return num.toLocaleString("en-US", { minimumFractionDigits: 2 });
    },
    UpdateReserva(item) {
      this.$store.state.update_reserva = "entrega_autos";
      this.$store.state.reserva = item;
      this.$store.state.fecha_entrega = this.fecha;
      this.$router.push({ name: "Reservas desde la Administración" });
    },
    openDetallesReserva(item) {
      this.item = item;
      this.monto_total = parseFloat(item.serviciosExtras) + (parseFloat(item.tarifaDiaria) * parseInt(item.dias));
      this.total_renta = parseFloat(item.tarifaDiaria) * parseInt(item.dias);
      this.$apollo.query({
        query: SERVICIOS_BY_RESERVE,
        variables: {
          id: this.item.id
        }
      }).then(response => {
        this.services_ex = response.data.servicesByReservation;
      });
      this.showDetallesReserva = true;
      document.body.classList.add("modal-open");
    },
    closeDetallesReserva() {
      this.item = null;
      this.monto_total = 0;
      this.total_renta = 0;
      this.showDetallesReserva = false;
      document.body.classList.remove("modal-open");
    },
    Contrato(item) {
      this.$store.state.anterior = "entrega_autos";
      this.$store.state.reserva = item;
      this.$router.push("/contratos/nuevo");
    },
    updateContrato(item) {
      this.$store.state.contrato = item;
      this.$store.state.update_contrato = "entrega_autos";
      this.$router.push("/contratos/editar");
    },
    EntregarAuto(id) {
      this.$apollo.mutate({
        mutation: ENTREGAR_AUTO,
        variables: {
          id: id
        }
      }).then(response => {
        if (response.data.entregarAuto.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Estado del contrato cambiado"
          });
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Error al cambiar el estado del contratos"
          });
        }
        this.loadContratos();
      });
    },
    DecodeId(id) {
      return decodeURIComponent(escape(window.atob(id))).split(":")[1];
    },
    async Cancelar_Contrato(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Si, cancelar contrato!"
      }).then(async (result) => {
        if (result.isDenied) {
          const { data } = await this.$apollo.mutate({
            mutation: CANCELAR_CONTRATO,
            variables: {
              id: id
            }
          });
          if (data.cancelarContrato.success) {
            this.loadContratos();
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "warning",
              title: "Contrato cancelado"
            });
          }
        }
      });
    },
    Cancelar_Reserva(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Si, cancelar la reserva!"
      }).then(async (result) => {
        if (result.isDenied) {
          const { data } = await this.$apollo.mutate({
            mutation: CANCELAR,
            variables: {
              id: id,
              agente: this.$store.state.userid
            }
          });
          if (data.cancelarReserva.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: "Reserva cancelada"
            });
            this.loadReservas();
          }
        }
      });
    }
  },
  created() {
    this.$store.state.Cargando = true;
    this.$store.state.update_reserva === "";
    this.$store.state.reserva = null;
    this.loadReservas();
  },
  computed: {
    ...mapGetters(["tienePermiso"])
  }
};
</script>

<style scoped>
.input-icon {
  background-image: url('../../assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 70%;
}

.es-hoy {
  font-weight: bold !important;
  background-color: #f3d540;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

@media (max-width: 550px) {
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 15px;
    width: 90%;
  }
}

</style>
